* {
  /* ↓ Now literally all elements display a sans-serif font */
  font-family: "Montserrat", sans-serif;
}

.fc-event-container .fc-event,
.fc-event-container .fc-event-dot,
.fc-event {
  background-color: #39374e !important;
  color: #babdbf !important;
  border-color: #39374e !important;
}

.nav-link-gdc-selected {
  font-weight: bold !important;
}
div.font-montserrat {
  font-family: "Montserrat", sans-serif;
}

div.font-roboto {
  font-family: "Robot", sans-serif;
}

div.font-opensans {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

div.rtl {
  direction: rtl !important;
  text-align: right;
}

.sticky-top {
  width: calc(100% - 280px);
  position: fixed;
}

.marginTop {
  margin-top: 7.2% !important;
}

.priority {
  padding: 10px 15px;
  margin-right: 10px;
}

.critical {
  margin-left: 55px;
  align-self: center;
}

.emergency {
  cursor: pointer;
  color: #dc0042;
}

.emergencySelected {
  cursor: pointer;
  background-color: #ffeeee;
  border: 0.5px solid #dc0042;
}

.high {
  cursor: pointer;
  color: #ff0000;
}

.highSelected {
  cursor: pointer;
  background-color: #ffeeee;
  border: 0.5px solid #ff0000;
}

.medium {
  cursor: pointer;
  color: #ffae42;
}

.mediumSelected {
  cursor: pointer;
  background-color: #f3f2d952;
  border: 0.5px solid #ffae42;
}

.low {
  cursor: pointer;
  color: #02005b;
}

.lowSelected {
  cursor: pointer;
  background-color: #e8e7f785;
  border: 0.5px solid #02005b;
}

.main-container {
  height: 85vh;
}

.datePickerContainer {
  width: 100%;
}
.w-Td {
  width: 30%;
}
.formTitle {
  border-bottom: 0.5px solid #cdc3c3c9;
}

.bw-0 {
  border-width: 0px;
}

.label-style {
  color: rgb(116, 118, 136);
}

.white-background {
  background: #ffffff !important;
}

/* progressbar width */
.widthEmergency {
  width: 90% !important;
}
.widthHigh {
  width: 70% !important;
}
.widthMedium {
  width: 50% !important;
}
.widthLow {
  width: 20% !important;
}
.widthCompleted {
  width: 100% !important;
}
.widthZero {
  width: 0% !important;
}
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 126px;
}

.icon-color {
  color: #5a5278;
}
.react-datetime-picker__wrapper {
  border: 1px solid #e8e9e9 !important;
}
.react-datetime-picker__inputGroup {
  padding: 5px !important;
}
.searchBarWidth {
  max-width: 300px !important;
  border: 2px solid #dee2e6 !important;
  height: 40px;
}
.input-group-btn > .btn {
  border: 2px solid #dee2e6 !important;
}
.fixedHeight {
  height: 120px !important;
}
.marginPagination {
  margin: 20px 35px !important;
}
.dropdownAlignment {
  margin: 10px 60px 0px 0px !important;
  top: 7px !important;
}
.dropdown-menu .dropdown-item {
  color: #333537 !important;
}
.displayNodata {
  text-align: center !important;
}
.selectWidth {
  width: 180px;
}
.selectColor {
  color: #2c2945;
}
.headerMargin {
  margin-top: 10px !important;
}
.companyMargin {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px !important;
}
.center {
  margin: auto;
}
.ellipsisStyle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.maxwidth-350 {
  max-width: 350px;
}
.tdCenter {
  padding: 15px 12px 15px 33px !important;
}
.center {
  padding: 15px 12px 15px 25px !important;
}
.icon-size {
  font-size: 20px !important;
}
.loaderAlignment {
  text-align: center !important;
  margin: 20px 0px;
  font-weight: 600;
}
.attachFileUl {
  list-style: none;
}
.documentCenter {
  padding: 15px 12px 15px 50px !important;
}
.table th {
  text-transform: none !important;
}
.table thead th {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #2c2945 !important;
}
.displayFlex {
  display: flex;
  flex-direction: row !important;
}
@media only screen and (max-width: 1500px) {
  .searchBarWidth {
    width: 200px !important;
    border: 2px solid #dee2e6 !important;
    height: 40px;
  }
  .filterIcon {
    margin-left: 5rem !important;
  }
  .selectWidth {
    width: 90px;
  }
  .react-datetime-picker__button {
    padding: 0px 2px 0px 0px !important;
  }
}
.card-spacing {
  padding-left: 15px;
  padding-right: 15px;
}

.datePadding {
  padding: 0;
}
.phoneSpacing {
  padding: 0.375rem 0.75rem;
  border-style: hidden !important;
  border: none !important;
}
.countrySelectContainer {
  position: absolute;
  z-index: 5;
  margin-left: 2px;
  height: calc(1.2em + 0.75rem + 7.9px);
  border-right-color: #cdc3c3c9;
  background: #fff;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border: 0px solid #cdc3c3c9;
  border-right-width: 0.5px;
  color: #495057;
  font-size: 14px;
}

/* Common Styling START */

a:hover {
  cursor: pointer !important;
}
/* Common Styling END */

/* Login - COuntry code selector START  */
.react-tel-input .country-list .flag {
  margin: 7px !important;
}
.react-tel-input {
  font-family: "Montserrat", sans-serif !important;
}
/* Login - COuntry code selector END  */

/* Login - Loader START */
.loader {
  width: 1.75rem;
  height: 1.75rem;
  color: #2185d0;
  display: block;
  position: relative;
}
.loader:before,
.loader:after {
  width: 1.75rem;
  height: 1.75rem;
  margin: -14px 0 0 -14px;
  position: absolute;
  content: "";
  top: 50%;
  left: 50%; 
}
.loader:before {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid currentColor;
  opacity: 0.15;
}
.loader:after {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  animation: loader 0.6s linear;
  animation-iteration-count: infinite;
  border: 3px solid;
  border-color: transparent;
  border-top-color: currentColor;
  box-shadow: 0 0 0 1px transparent;
}

h6.cursor-pointer {
  color: #02005b; 
}

i.fe.cursor-pointer
{
  padding: 10px !important;
}

/* Login - Loader END */

/* Menu - Styling  START */

.ps-menu-button {
  padding: 0 !important;
  font-weight: 500;
}
.ps-menu-icon {
  width: 30px !important;
  height: 30px !important;
}
.ps-menu-label {
  font-weight: 600 !important;
}
a.ps-menu-button:hover,
a.ps-menu-button:hover > .ps-menu-label {
  color: #fefefe !important;
  background-color: #7070a3 !important;
}

a.ps-menu-button:hover span.ps-menu-icon svg {
  fill: #fefefe !important;
}

.ps-submenu-content.ps-open {
  margin-left: 12px;
  font-size: 15px;
  font-weight: normal !important;
}
/* Menu - Styling  END */

.onError {
  border: 0px;
  margin: 3px;
  height: calc(1.5em + 0.75rem + 2px);
  border-right: 1px solid #cdc3c3c9;
}

.phoneInput {
  padding: 0.56rem 0.56rem 0.56rem 5rem;
}

.address {
  position: absolute;
}
.addButton {
  border-color: #5a5278;
  color: #5a5278;
  margin-left: 1.5rem;
  min-width: 100px;
}
.phoneExtensionStyle {
  margin-top: 1.5rem;
  /* width: 20%; */
  width: 25%;
}
.labelStyling {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-bottom: 5px;
  font-weight: 500 !important;
}

.saveButton {
  min-width: 100px;
  min-height: 36px;
}
.PhoneExtensionInput {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.PhoneExtensionInputStyle {
  /* width: 80%; */
  width: 75%;
}
.AddressLabel {
  color: #5a5278 !important;

  font-size: 21px;
  white-space: nowrap;
  font-weight: 400;
}

.css-13cymwt-control {
  height: 39px !important;
  min-height: 39px !important;
  border: thin solid #cdc3c3c9 !important;
}

.css-1dimb5e-singleValue span {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.css-14el2xx-placeholder {
  color: #495057b0 !important;
  font-size: 14px !important;
}

.react-datetime-picker__wrapper {
  border: thin solid #e8e9e9 !important;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
  color: #495057;
  border-style: hidden !important;
  /* height: 35px !important; */
}

.react-datetime-picker__inputGroup ::placeholder {
  color: #495057b0 !important;
  font-size: 14px !important;
}

.react-datetime-picker__button svg {
  stroke: #495057a1 !important;
}

.container_spacing {
  margin: 30px 30px 30px 60px;
}

.card_spacing {
  padding: 0 15px;
}

.css-1s2u09g-control {
  border-style: hidden !important;
}

.textFieldHeight {
  padding: 0.56rem 0.75rem;
}

.paddingStyle {
  padding: 0;
}

.line_spacing {
  margin-top: 2rem;
}
.dateDisable {
  background-color: #f0f0f0 !important;
}
/* .dateDisable button {
  background-color: #f0f0f0 !important;
} */
.disableStyling input {
  /* background-color: #f0f0f0 !important; */
  color: #1a1818 !important;
}
.timeStyle {
  padding: 0.44rem;
}

.priorityBtnStyle {
  padding: 0 0.5rem;
}

.custom-checkbox .custom-control-label:before {
  border: 1px solid #5a5278 !important;
}
.subMenuStyle {
  width: 18px;
  height: 18px;
  stroke: #f1f1f182;
}

.addButton {
  border-color: #5a5278;
  color: #5a5278;
  margin-left: 1.5rem;
  min-width: 100px;
}

.saveButton {
  min-width: 100px;
  min-height: 36px;
}

.phoneExtensionStyle {
  margin-top: 1.5rem;
}

.rightIconStyle {
  padding: 0.7rem 0.5rem 0.5rem 0.5rem;
  min-width: 40px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.rightIconStyleError {
  padding: 0.7rem 0.5rem 0.5rem 0.5rem;
  min-width: 40px;
  position: absolute;
  bottom: 27px;
  right: 5px;
}

.iconStyling {
  display: flex;
  justify-content: flex-end;
}

.display {
  display: inline-block !important;
}
.filenameAlignment {
  margin-top: 20px !important;
  padding-left: 0px !important;
  text-decoration: none !important;
}

.filterIcon-color {
  color: #46435e !important;
  font-size: 13px !important;
}
.filterIcon-color-active {
  color: #11aaff !important;
  font-size: 13px !important;
}
.InputTextAddComment {
  border: 0;
  padding: 0.9rem;
}
.InputTextAddComment {
  border: 0;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  padding-left: 1.5rem;
  background-color: #f0f0f0;
}
.main-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  min-height: 80px;
  width: 91%;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
}
.card-contents {
  display: flex;
  align-items: baseline;
}
.ellipsisIcon {
  margin-left: auto !important;
}
.userProfile {
  margin-top: 1rem;
}
.commentListStyling {
  padding-left: 0.5rem;
}
.messageHeading {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.commentSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
  /* position: fixed;
  bottom: 0; */
}
.commentTextField {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 0.9rem;
  margin-top: 3px;
}
.commentMicIcon {
  padding-left: 15px;
  padding-right: 15px;
}
.commentSectionIcon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 8%;
  padding-right: 5rem;
}
.commentIconStyling {
  padding-right: 5px;
  padding-left: 3px;
}
.commentSendIconStyling {
  transform: rotate(45deg);
}
.dropdown-menuStyling {
  min-width: 10rem !important;
}
.NoComment {
  height: 250px;
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  padding: 7rem 0;
  font-family: "Montserrat", sans-serif;
}
::marker {
  /* display: none!important; */
  color: white !important;
}
.imageIconColor {
  color: #2c2945 !important;
  padding-right: 15px !important;
  font-size: 14px !important;
  font-weight: 100 !important;
}
.EyeIconColor {
  color: #2c2945 !important;
  font-size: 14px !important;
  font-weight: 100 !important;
}
.fileNameColor {
  color: #2c2945 !important ;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding-right: 15px !important;
  display: inline-block;

  /* width: 400px !important; */
  overflow: hidden;
  padding-right: 15px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.heightImageli {
  height: 40px !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
  border: 1px solid #e8e9e9 !important;
  border-radius: 5px !important;
  padding: 8px !important;
  margin-top: 10px !important;
  /* width: 500px !important; */
}
li::before::marker {
  display: none !important;
}
.documentExpiryCheck {
  align-self: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.container-spacingStyling {
  margin-top: 4rem;
  margin-left: 11rem;
  margin-right: 9rem;
}
.dropdown-itemStyling {
  display: flex !important;
}
/* .imageStyling { */
.TaskDetailsStyling {
  display: flex;
  /* margin: 50px;   */
  margin: 10px 10px 10px 50px;
}
.TaskInfo {
  padding: 0.5rem 1.3rem;
}
.TaskInfoTitle {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.2;
}
.divider {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

.imageStyling {
  width: 30% !important;
  display: block !important;
  margin-top: 10px, important;
}

/* .styles-module_wrapper__1I_qj {
  left: 350px !important;
  top: 50px !important;
  width: 75% !important;
  height: 90% !important;
  opacity: 1 !important;
} */
/* .styles-module_content__2jwZj{
  width: 100%!important;
} */
/* .styles-module_image__2hdkJ {
  height: 100% !important;
  z-index: 100 !important;
}

/* .styles-module_image__2hdkJ {
  height: 80% !important;
  width: 100% !important;
}
.styles-module_slide__1zrfk {
  width: 100% !important;
} */

.line {
  height: 1px;
  background: #5a5278;
  width: 30%;
  margin: 0 5px;
}

.orStyling {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

/* .forLogo {
  width: 130px;
} */

.cardStyling {
  padding: 10px 15px !important;
}

.imageDisplay {
  flex-direction: column !important;
}
.toastErrorColor {
  background-color: #e74c3c !important;
  color: #fff !important;
  width: 100%;
}
.locationIcon {
  float: right;
  margin-right: 20px;
  margin-top: -33px;
  position: relative;
  z-index: 2;
  font-size: 25px;
  color: #495057a1 !important;
}
.CommentCard_spacing {
  padding: 15px 15px;
  /* margin-left: 0.9rem; */
}
.TaskStyling {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.3rem 0.5rem;
}
.commentLabel {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  /* padding: 0.9rem 0; */
}
.TaskAttachment {
  margin-left: 0.9rem;
  margin-bottom: 0.9rem;
}

.AttachmentBoxStyling {
  display: flex;
  flex-direction: row;
  /* padding: 0.9rem;  */
  flex-wrap: wrap;
}
.taskStatusStyling {
  padding: 0 0.5rem;
  border-radius: 3px;
}
.fileIconStyling {
  background: #fff;
  color: black;
  padding: 0;
}
.imageViewerStyling {
  margin: 0 1.5rem;
  border-top: 1px solid #ccc;
}
.attachmentBackStyling {
  margin-left: 0.9rem;
  margin-right: 5.4rem;
  background: #f8f9fa;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.taskCardContentStyling {
  font-size: 14px;
}

.taskHeading {
  color: #2c2945;
  font-weight: 700;
}
.viewDocumentStyling {
  background: #f0f0f0 !important;
  color: #999;
  padding: 0.56rem 0.75rem;
}
.pathName {
  color: #999;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding-right: 15px !important;
}
.imageColor {
  color: #999;
  padding-right: 15px !important;
  font-size: 14px !important;
  font-weight: 100 !important;
}

.viewDocumentAttchment {
  background: #f0f0f0 !important;
  color: #999;
  height: 40px !important;
  display: inline-block !important;
  border: 1px solid #cdc3c3c9 !important;
  border-radius: 5px !important;
  padding: 8px !important;
}

.form-check-label {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.modal-title {
  width: 92% !important;
  color: #2c2945 !important;
  font-weight: 500 !important;
  font-size: 20px !important;
}
.modalStyling {
  display: flex !important;
  justify-content: flex-end;
  margin-top: 0.5rem;
  align-items: baseline;
}
.modalSaveBtn {
  padding: 0.45rem 1rem;
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em;
  color: #000;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
  background: transparent
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E")
    50%/1em auto no-repeat;
}

.tdStyling {
  border-top: none !important;
}
.W35 {
  width: 35%;
}
.SharedByStyle {
  color: #5a5278;
  font-size: 15px;
  margin-top: 0.5rem;
}
.taskName {
  overflow: auto;
  /* white-space: pre-line; */
  text-overflow: ellipsis;
  white-space: nowrap;
}
.taskNameStyling {
  width: 10%;
}
.widthTd {
  width: 25%;
}
.NoDataDisplayStyling {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.icon-color:hover {
  transform: translateY(-4px) scale(1.02);
}
.scrollBox {
  overflow: auto;
  white-space: nowrap;
  /* border: 1px solid red; */
  width: 100%;
}
.cardHeight {
  min-height: 65vh;
}
.disabledCss {
  background-color: #f0f0f0 !important;
  color: #1a1818 !important;
}
.list-unstyled li:hover {
  -webkit-transform: translateY(-4px) scale(1.02);
  -moz-transform: translateY(-4px) scale(1.02);
  -ms-transform: translateY(-4px) scale(1.02);
  -o-transform: translateY(-4px) scale(1.02);
  transform: translateY(-4px) scale(1.02);
  z-index: 999;
}
.fixedCardHeight {
  min-height: 65vh;
}

.manageIconColor {
  color: rgba(2, 0, 91, 0.849);
}
.paginationContainer {
  align-self: end;
  margin: 30px 0px 10px 0px;
}
.iconPinColorStyle {
  color: rgba(2, 0, 91, 0.849);
  transform: rotate(40deg);
}
.iconFlagColorStyle {
  color: rgb(255, 0, 0);
}
.tabeldisplay {
  display: table;
}
.taskProgressStyle {
  width: "72%";
  overflow: "hidden";
  text-overflow: "ellipsis";
  white-space: "nowrap";
  text-align: "end";
}
.responsiveTable {
  overflow-x: auto;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #928e8e !important
  ;
}
::-webkit-scrollbar-thumb {
  background-color: #928e8e !important;
  border-radius: 6px;
}
.voiceNote_spacing {
  margin: 0px;
}
.NoDataStyle {
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  padding: 1rem 0;
}
.commentContainer {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-inline-start: 10px;
  scroll-behavior: smooth;
}
.commentContainer::-webkit-scrollbar {
  width: 10px !important;
  margin-left: 5px;
}
.commentContainer::-webkit-scrollbar-thumb {
  border-radius: 6px;
  border: 6px solid #c1c2c1;
}
.loadBoxshadow {
  position: relative;
  z-index: 999;
  margin-inline: 15px;
  box-shadow: 0px 8px 30px 20px #a3a4a780;
}
.new_timeline .bullet.primary {
  border-color: #02005b;
  background-color: #02005b;
}

.cursor-pointer {
  cursor: pointer;
}

.backnavigation-iconColor {
  color: #2c2945;
}
.sharedByMe {
  flex-direction: row;
  margin-right: 35px;
  justify-content: flex-end;
  color: #5a5278;
}
.sharedByMeLable {
  margin-left: 5px;
  margin-top: 10px;
}
.styles-module_wrapper__1I_qj {
  z-index: 10322 !important;
}

.fontSize-22 {
  font-size: 22px;
}
.fontSize-24 {
  font-size: 24px;
}

.container-topPadding {
  padding: 8px 16px;
}

.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.shareIcon {
  width: 40%;
  height: 15px;
  border-radius: 40%;
  background: white;
  position: absolute;
  left: 18px;
  top: 24px;
  right: 31px;
  margin-right: 20px;
}

.centerNoDataForTable {
  padding-top: 12% !important;
}

.addTaskButton {
  font-size: 16px;
}
.customReminderRadioButtons div {
  display: flex;
  flex-direction: column;
}

.taskApprovalButton {
  display: flex;
  gap: 4px;
}

.tableStrip {
  background-color: rgba(0, 0, 0, 0.04);
}
.card-header-spacing {
  padding: 20px 0px 20px 0px;
}
.contact-profile {
  width: 45px;
  height: 35px;
  max-width: 6rem;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(41, 43, 48, 0.3);
  margin-left: 25px;
}
.buttonTextFontWeight {
  font-weight: 500;
  font-size: 16px;
}
.cutomButtonBorder {
  border: 2px solid #dee2e6 !important;
  height: 41px !important;
}
.disabled-icon {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.subtaskBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 5px;
}

.subtaskPermissionBox {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.active-icon {
  background: #5a5278;
  color: white;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
}
.chatMessageBadgeStyle {
  color: #ffffff;
  text-align: center;
  width: 24px;
  line-height: 0px !important;
  border-radius: 12px;
  font-size: 0.8rem !important;
}
.chatMessageBadgeContainer {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  background-color: #7372b8;
}
.customReminderRadioButtons div {
  display: flex;
  flex-direction: column;
}
.loadMoreStyle {
  padding: 10px;
  border-radius: 5px;
  box-shadow: inset 0px 8px 45px -24px rgba(136, 137, 138, 0.87);
  -webkit-box-shadow: inset 0px 8px 45px -24px rgba(136, 137, 138, 0.87);
  -moz-box-shadow: inset 0px 8px 45px -24px rgba(136, 137, 138, 0.87);
}

.subtaskIcon {
  font-size: 20px;
}

.addSubtaskFormBox {
  width: min-content;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-weight: 600;
  color: #7372b8;
}

.addSubtaskFormBox--active {
  color: #3e3d83;
}

.taskHeaderMenu {
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.viewAddSubtask {
  width: auto;
  color: #5a5278;
}
.typeFilterIcon::after {
  display: none !important;
}
.dropdown-toggle::after {
  display: none !important;
}
.cardLogo {
  position: absolute;
  top: 12px;
}
.chatInfoBackground {
  background: linear-gradient(180deg, #02005b -40%, #ffffff 100%);
  background-size: 100% 55%;
  background-repeat: no-repeat;
}
.addContactButton {
  margin-top: 2px;
  margin-left: 15px;
}
.filterIconSize {
  font-size: 17px;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  max-height: 250px !important;
  min-width: 250px !important;
  max-width: 440px !important;
}
.css-r8u8y9 {
  max-height: 250px !important;
  overflow: auto !important;
  min-width: 250px !important;
  max-width: 440px !important;
}

.filterContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  gap: 10px;
}

.dateRangeBox,
.filterIconBox {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.dateRangeFormControl {
  height: 41px;
  font-size: 14px;
  text-align: center;
  line-height: 1.5;
  color: #000000;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  padding: 9px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dateRangeFormControl::placeholder {
  color: #495057b0;
  font-size: 14px;
}

.dateRangeFormControl:focus {
  outline: 2px solid #ced4da;
}

.dateRangeIcon {
  color: #495057 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 4.5px;
  left: 1px;
  cursor: pointer;
}

.dateRangeFilterIcon {
  color: rgba(2, 0, 91, 0.849);
  cursor: pointer;
}

.flag {
  background: transparent;
}
.iconSize {
  font-size: 15px !important;
}

.fontSize-18 {
  font-size: 18px;
}

.hideSpinBtn input[type="number"]::-webkit-inner-spin-button,
.hideSpinBtn input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.shareModalSelect {
  display: flex !important;

  margin-top: 1.5rem;
  align-items: baseline;
  margin-left: 12px;
}
.shareDropDown {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}
.shareDropDownWidth {
  display: flex;
  /* flex: 0.5  */
  width: 50%;
}
.customShareFilters {
  transform: translate3d(250, 46, 0) !important;
}
.shareIconSize {
  font-size: 20px;
}
.shareDropDownWidth {
  display: flex;
  flex: 0.5;
}
.shareDropDownWidthCompany {
  /* width: 47%; */
  width: 100%;
}
.shareDropDownWidth {
  display: flex;
  flex: 0.5;
}
.shareDropDownWidth {
  width: 48%;
}

.groupMemberProfileBox {
  padding: 10px 9px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 7px;
  row-gap: 10px;
}

.groupMemberProfileHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.groupMemberProfileIcon {
  cursor: pointer;
  color: #ced4da;
  background-color: #495057;
  border-radius: 50%;
  font-size: 18px;
  position: absolute;
  top: 22px;
  right: 16px;
}

.groupMemberProfileIcon--addMemberAlign {
  right: 30px;
}

.groupMemberProfileName {
  font-size: 12px;
  text-align: center;
}

.addGroupIcon {
  cursor: pointer;
  position: fixed;
  right: 20px;
  color: #5a5278;
  width: 50px;
  height: 50px;
  bottom: 64px;
  z-index: 999;
}

.showGroupMembers .name {
  font-size: 15px;
  color: grey;
}

.hideSpinBtn input[type="number"]::-webkit-inner-spin-button,
.hideSpinBtn input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.groupInfoSearch {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.msgHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.msgHeaderSub {
  display: flex;
  align-items: center;
}

.addGroupMemberHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.businessCardFontColor {
  color: #ffffff;
}
.businessCardFooterColor {
  color: #ffffff96;
}
.businessCardBackgroundColor {
  background-color: #02005b;
}
.businessCardContactNameStyling {
  align-items: flex-end;
  font-size: 25px;
  font-weight: 500;
}
.businessCardLogoStyles {
  display: flex;
  justify-content: flex-end;
}
.businessCardDesignation {
  font-size: 17px;
  font-weight: 500;
}
.businessCardHrColor {
  background-color: rgba(66, 65, 116, 1);
}
.businessCardLogoStyles {
  display: flex;
  justify-content: flex-end;
}

.businessCardHrColor {
  background-color: rgba(66, 65, 116, 1);
}
.react-datetime-picker__calendar,
.react-datetime-picker__clock {
  z-index: 11 !important;
}

.permissionModal .modal-content {
  width: 374px;
  text-align: center;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  color: rgb(0 0 0 / 24%) !important;
}
.custom-radioButton {
  border: 2px solid #dee2e6;
  border-radius: 5px;
  padding: 3px 8px;
}
.cardhover {
  cursor: pointer;
}
.cardhover :hover {
  position: absolute;
  transform: translateY(-10px);
  width: 310px !important;
  z-index: 2;
  background: #ffffff;
  transition: all 0.5s ease-in-out;
}
.cardhoverText {
  cursor: pointer;
}
.cardhoverText:hover {
  position: relative;
  transform: none;
  width: auto;
  z-index: 1;
  background: transparent;
  transition: none;
}
.eventListscroll {
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.eventListscroll::-webkit-scrollbar {
  width: 10px !important;
}
.eventListscroll::-webkit-scrollbar-thumb {
  border-radius: 6px;
  border: 6px solid #c1c2c1;
}
.ellipsisEventCard {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.shareUserFilterBox {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: center;
}

.shareFilterItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calendarTitleBox {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.centerElementsFlex {
  align-items: center;
  justify-content: center;
}

.sortingFilterTitleBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sortingClearAll {
  color: #7372b8;
}

.commonWidth {
  flex: 1;
}

.emailParentSection {
  display: flex;
  width: 99%;
  justify-content: space-between;
  align-items: "center";
}
.emailSectionOne {
  display: flex;
  width: 80%;
}
.emailUserName {
  display: flex;
  width: 13%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: wrap;
}
.emailUserNameSpan {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emailSubject {
  display: flex;
  width: 83%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emailActions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 20%;
}
.emailActionsColor {
  background-color: rgb(2, 0, 91);
  color: #fff;
  margin-top: 3px;
}
.emailActionsIcons {
  display: flex;
  justify-content: flex-end;
  margin-left: 5px;
}
.react-datetime-picker__calendar,
.react-datetime-picker__clock {
  z-index: 11 !important;
}
.emailActionsDate {
  display: flex;
  justify-content: flex-end;
  width: 30%;
  margin-left: 5px;
}
.commonDisplay {
  display: flex;
}

.viewEmailSubject {
  font-size: 23px;
  font-weight: 500;
}
.viewEmailUserName {
  flex-direction: row;
  margin-top: 30px;
  justify-content: space-between;
}
.viewEmailUserStyles {
  flex-direction: column;
  margin-left: 15px;
}
.viewEmailFromName {
  font-size: 18px !important;
}
.viewEmailActions {
  height: 65%;
  padding: 5px 10px;
  margin-right: 10px;
}

.viewEmailSenderDetails {
  margin-top: 25px;
  border: 2px solid #d2d2d2;
  width: 50%;
  border-radius: 15px;
}
.viewEmailTaskStatus {
  display: flex;
  justify-content: flex-end;
}

.viewEmailTaskStatusStyles {
  padding: 5px 10px;
  margin-right: 20px;
  margin-top: 10px;

  background-color: #fff;
  position: absolute;
  top: 135px;
  z-index: 11;
}

.viewEmailSenderDetailsFrom {
  margin: 25px 0px 8px 15px;
  flex: 1;
}
.viewEmailCommonTitle {
  display: flex;
  width: 8%;
}

.viewEmailCommonMail {
  display: flex;
  width: 90%;
  font-weight: 500;

  flex-wrap: wrap;
}

.viewEmailCommonDetails {
  margin: 15px 0px 20px 15px;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.selected {
  background-color: #e0e0e0;
}

.unclickable {
  pointer-events: none;
  cursor: not-allowed;
}
.taskReportGraphNoData {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
}

.taskReportGraph .apexcharts-legend-series {
  display: flex;
  align-items: center;
  gap: 10px;
}

.labelStyle {
  font-weight: 500;
  font-size: 14px;
}

.performanceInfoTitle {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.2;
  color: #2c2945;
  padding-bottom: 0px !important;
}

.selectedItemStyle {
  background-color: #babdbf47;
}

.customFullCalendar .fc-event {
  display: flex;
}

.customFullCalendar .fc .fc-button {
  padding: 0 0.6em;
  background-color: #5a5278;
  border-color: #5a5278;
  color: #fff;
}

.customFullCalendar .fc-event-time {
  display: none;
}

.customFullCalendar .fc-button-active,
.customFullCalendar .fc-state-disabled,
.customFullCalendar .fc-button:hover {
  background-color: #7372b8 !important;
  border-color: #7372b8 !important;
}

.calendarTitleBox h3 {
  font-weight: 500;
}
.calendarUserName {
  font-size: 18px;
}

.calendarUserRole {
  font-size: 15px;
}

.calendarHeaderBox {
  display: flex;
  gap: 4px;
}
.modal-content {
  margin: 1rem;
}
.apexcharts-legend-text {
  text-transform: capitalize;
}
.apexcharts-legend-series {
  margin: 2px 7px !important;
}
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.notificationDetails {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
}

.notificationHeader {
  display: flex;
  justify-content: space-between;
}

.taskHeadingLineBottom {
  border-bottom: 1px solid #ccc;
}

.taskHeadingLineTop {
  border-top: 1px solid #ccc;
}

.taskAcceptBtn {
  background-color: #02005b;
  color: #fff;
}

.taskAcceptBtn:hover {
  color: #fff;
}

.taskRejectBtn {
  color: #02005b;
  border-color: #02005b;
}

.taskRejectBtn:hover {
  background-color: #02005b;
  color: #fff;
}

.taskDetailsStatus {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}
.custom-page-title {
  font-size: 22px;
  margin-bottom: 0;
  margin-right: 20px;
  margin-left: 20px;
  white-space: nowrap;
}
.arrow-icon-size {
  font-size: "23px" !important;
}

.staffProfileIcon {
  text-align: center;
  top: 62px !important;
  left: 76px !important;
}

.performanceContainer {
  display: flex;
  flex-direction: column;
  margin-left: 58px;
  margin-right: 18px;
}

.performanceTitle {
  font-weight: 600;
}

.performanceDetails {
  padding: 4px;
  font-size: 16px;
}

.performanceChartBox {
  position: "relative";
}

.performanceEmailDetails {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  color: #02005b;
  border-color: #02005b;
}

.performanceChartValueBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.performanceChartValue {
  font-size: 22px;
  font-weight: 700;
}

.businessCardIcons {
  font-size: 17px;
  display: inline;
}
.businessCardDetails {
  width: 90%;
}
.businessCardDetailsParentDiv {
  display: flex;
  flex-wrap: wrap;
}
.linkWithTask {
  display: flex;
  padding: 0px 20px;
}
.workingDayStyle {
  color: #02005b;
}

.absentDayStyle {
  color: #ff0000;
}
.presentDayStyle {
  color: #1371ff;
}

.orgCardContainer {
  height: 75px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 11px;
  border: 1px solid #a8a5a5;
}

.orgCardImg {
  position: absolute !important;
  top: -49px;
  left: 44px;
  z-index: 999;
  overflow: hidden;
}

.orgCardContent {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.orgCardName {
  font-size: 14px;
  font-weight: 500;
}

.orgCardRole {
  font-size: 12px;
}

.customVoiceNotereminderSeperator {
  border-top: 2px solid rgb(189, 189, 189);
}

::-webkit-scrollbar {
  width: 4px !important;
}
#pdf-download {
  display: none; /*enable or disable doc viewer pdf download*/
}
.showTooltip {
  display: block;
  position: absolute;
  top: 17%;
  left: 53%;
  transform: translateX(-50%);

  background-color: black;

  color: #ffffff;
  padding: 8px;
  border-radius: 4px;
}
.contactBusinessCardParentDiv {
  display: flex;
  flex-direction: row;

  padding: 0.3rem 0.5rem;
}
.TaskStylingDesignation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.2rem 0.5rem;
}
.passwordIcon-alignement {
  margin-right: 10px;
}


.profileActive.active {
  background:#85849e !important;
}
.logoutActive.active {
  background:none !important;
  color: #333537 !important
}
.logoutActive:hover {
  background:#85849e !important;
  color: #333537 !important
}


@media only screen and (min-width: 768px) {
  .rightIconStyleError {
    padding: 0.7rem 0.5rem 0.5rem 0.5rem;
    min-width: 40px;
    position: absolute;
    bottom: 37px;
    right: 5px;
  }
}

@media only screen and (min-width: 1531px) {
  .rightIconStyleError {
    padding: 0.7rem 0.5rem 0.5rem 0.5rem;
    min-width: 40px;
    position: absolute;
    bottom: 22px;
    right: 5px;
  }
}
.fc-highlight {
  background: #7ecfe1fa !important;
}

.docShareIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  position: absolute;
  left: 14px;
  top: 20px;
  color: #02005B;
  text-align: center;
  padding: 3px;
  font-size: 13px;
}

.headerButton:focus {
  outline: none !important;
}

.boxLine {
  border-bottom: 0.5px solid #cdc3c3c9;
  margin: 10px 0 20px 0;
  width: 100%;
}
.toogleIconSize {
  font-size: 35px;
}

.offcanvas-active .section-body,
.offcanvas-active .page .section-body {
  padding: 0px !important;
}

.offcanvas-active .container-fluid {
  padding-left: 0px !important;
}

.offcanvas-active .page-header {
  padding: 20px !important;
}

.importDateFooter {
  margin-top: 18px;
  padding-bottom: 0;
}

#paContact .form-control:disabled,
.form-control[readonly] {
  background: #e5e5e58f;
}

.clientListMenu .dropdown-menu .dropdown-item {
  padding-left: 0 !important;
  display: flex;
  align-items: center;
}

#changePassword .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 20px;
}

#changePassword .css-b52kj1 {
  font-size: 20px;
}

#changePassword
  .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input::placeholder {
  font-size: 18px;
}

#changePassword .css-b52kj1 {
  font-size: 18px;
}

.formikLocationIcon {
  margin-right: 8px;
}

.profileActive.active {
  background: #85849e !important;
}
.logoutActive.active {
  background: none !important;
  color: #333537 !important;
}
.logoutActive:hover {
  background: #85849e !important;
  color: #333537 !important;
}